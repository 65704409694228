import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../scss/legal.scss"
import CookieConsentContext from "../context/CookieContext";
import CookiePopup from "../components/cookiepopup";

const PrivacyPage = () => (

  <CookieConsentContext.Consumer>
    {({ state, dispatch, popupIsOpen }) => (
    <div className="legal">
      <Helmet
        htmlAttributes={{
          class: 'legal-page'
        }}
        bodyAttributes={{
        class: 'legal-page'
      }}>
        <title>Πολιτική απορρήτου</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Πολιτική απορρήτου</h1>
      <div className="text--md color--black"><h3><b>Δήλωση απορρήτου και
        προστασίας Προσωπικών Δεδομένων</b></h3>
        <p>Για εμάς στην Souldesign η προστασία των Προσωπικών Δεδομένων των
          πελατών μας και ο σεβασμός της ιδιωτικής ζωής του ατόμου στο διαδίκτυο
          αποτελεί αυτονόητη δέσμευση. Λαμβάνουμε όλα τα απαραίτητα οργανωτικά
          και τεχνικά μέτρα που απαιτούνται ώστε να διασφαλίσουμε την ασφάλεια,
          την διαθεσιμότητα και την εγκυρότητα των δεδομένων των χρηστών και
          πελατών μας.</p>
        <p>Είναι πολύ σημαντικό για εμάς αυτή η ισχυρή δέσμευση να είναι
          ξεκάθαρη σε όλους τους πελάτες και χρήστες των υπηρεσιών μας. Για το
          λόγο αυτό δημιουργήσαμε την παρούσα "Δήλωση απορρήτου - προστασίας
          Προσωπικών Δεδομένων" που περιγράφει και επεξηγεί τι είδους προσωπικά
          δεδομένα συλλέγουμε, πως τα διαχειριζόμαστε και που μπορούν να
          χρησιμοποιηθούν.</p>
        <p>H Souldesign παρέχει υπηρεσίες οπτικής επικοινωνίας, εστιάζοντας
          στο branding, design, τις ψηφιακές εφαρμογές καθώς και της
          έρευνας &amp; επικοινωνίας. Η παρούσα δήλωση αναφέρεται στο σύνολο των
          προϊόντων υπηρεσιών που παρέχουμε.</p>
        <h3><b>Προσωπικά δεδομένα που συλλέγουμε:</b></h3>
        <p>Η Souldesign συλλέγει και επεξεργάζεται δεδομένα αποκλειστικά και
          μόνο για τους σκοπούς της νόμιμης και ορθής λειτουργίας της και για να
          προσφέρει στους πελάτες της και τους χρήστες του διαδικτύου την
          καλύτερη δυνατή εμπειρία χρήσης. Ορισμένα από αυτά τα δεδομένα
          παρέχονται άμεσα από τους πελάτες μας στα πλαίσια της συμφωνηθείσας
          συνεργασίας μας. Ορισμένα δεδομένα καταγράφονται έμμεσα μέσω της
          επισκεψιμότητας του ισότοπου μας. Έτσι καταγράφονται δεδομένα, όταν
          χρησιμοποιούνται τεχνολογίες όπως τα cookies ή λαμβάνονται αναφορές
          σφαλμάτων ή στατιστικών δεδομένων χρήσης από τα προϊόντα λογισμικού
          για την διάγνωση προβλημάτων ή για την βελτίωση τους. Σε κάθε
          περίπτωση συλλογής ή αποστολής δεδομένων προηγείται σαφής και ρητή
          αποδοχή από την πλευρά του χρήστη με κατάλληλο στοιχείο διεπαφής (user
          interface).</p>
        <p>Στα δεδομένα που συλλέγουμε μπορεί να περιλαμβάνονται τα εξής:</p>
        <h3><b>Δεδομένα σχετικά με εσάς</b></h3>
        <p>Αυτό περιλαμβάνει το όνομα, τη διεύθυνση, τον αριθμό τηλεφώνου και τη
          διεύθυνση ηλεκτρονικού ταχυδρομείου. Σε περίπτωση που είστε εταιρικός
          πελάτης, θα συλλέξουμε επίσης πληροφορίες σχετικά με την εταιρεία ή το
          ίδρυμά σας. Θα ζητήσουμε επίσης αντίγραφα ταυτοτήτων και των
          προσωπικών σας αριθμών, όπως τον αριθμό φορολογικού σας μητρώου ή τα
          σχετικά αναγνωριστικά στοιχεία.</p>
        <h3><b>Ιστορικό Συναλλαγών</b></h3>
        <p>Εκτός από τα προσωπικά δεδομένα που διαθέτει η Souldesign σε σχέση
          με τους πελάτες μας, διατηρούμε επίσης ένα αρχείο σχετικά με το
          ιστορικό συναλλαγών και εντολών.</p>
        <h3><b>Ιστορικό Επικοινωνίας</b></h3>
        <p>Παρακολουθούμε τις περιόδους κατά τις οποίες επικοινωνήσατε με τη Souldesign συμπεριλαμβανομένων τηλεφωνικών εγγραφών, μηνυμάτων
          ηλεκτρονικού ταχυδρομείου ή με άλλους τρόπους με τους οποίους
          επικοινωνήσατε μαζί μας.</p>
        <h3><b>Επισκέψεις στην ιστοσελίδα και χρήση της εφαρμογής</b></h3>
        <p>Σε περίπτωση που επισκεφθείτε την ιστοσελίδα της Souldesign, θα
          συλλέξουμε επίσης τη διεύθυνση IP που χρησιμοποιείται.</p>
        <h3><b>Δεδομένα αντιμετώπισης προβλημάτων και βοήθειας</b></h3>
        <p>Όταν οι πελάτες επικοινωνούν με την Souldesign για αντιμετώπιση
          προβλημάτων και επίλυση, συλλέγουμε δεδομένα σχετικά με τους πελάτες
          καθώς και άλλες λεπτομέρειες που σχετίζονται με το περιστατικό. Στα εν
          λόγω δεδομένα περιλαμβάνονται δεδομένα επικοινωνίας ή ελέγχου
          ταυτότητας, το περιεχόμενο των συνομιλιών και άλλων επικοινωνιών με
          την Souldesign, καθώς και δεδομένα σχετικά με την προσφερθείσα
          υπηρεσία.</p>
        <h3><b>Cookies και Παρόμοιες τεχνολογίες</b></h3>
        <p>Η Souldesign χρησιμοποιεί cookies (μικρά αρχεία κειμένου που
          τοποθετούνται στη συσκευή σας) και παρόμοιες τεχνολογίες και υπηρεσίες
          που χρησιμοποιούν την διεύθυνση IP για την παροχή των τοποθεσιών της
          στο web, αλλά και επιτρέπουν την εκτέλεση βασικών λειτουργιών του
          site, την εύρυθμη λειτουργία του και τη βέλτιστη εμπειρία των
          επισκεπτών του. Τα cookies μας επιτρέπουν, μεταξύ άλλων, να
          αποθηκεύουμε τις προτιμήσεις και τις ρυθμίσεις σας, σας επιτρέπουν να
          πραγματοποιείτε είσοδο, παρέχουν διαφημίσεις βάσει ενδιαφερόντων,
          παρέχουν ειδοποιήσεις (notifications), συμβάλλουν στην καταπολέμηση
          των απατών, ενώ επίσης αναλύουν τις επιδόσεις των τοποθεσιών μας στο
          web και των ηλεκτρονικών υπηρεσιών μας. Για τους παραπάνω σκοπούς
          γίνεται επίσης χρήση υπηρεσιών όπως π.χ. Google Analytics.</p>
        <p>Έχετε στη διάθεσή σας μια ποικιλία εργαλείων για να ελέγχετε τα
          cookies, τα προειδοποιητικά σήματα web και άλλες παρόμοιες
          τεχνολογίες, όπως είναι οι έλεγχοι του προγράμματος περιήγησης για τον
          αποκλεισμό και τη διαγραφή cookies και οι έλεγχοι από ορισμένες τρίτες
          υπηρεσίες παροχής ανάλυσης για την εξαίρεση από τη συλλογή δεδομένων
          μέσω των προειδοποιητικών σημάτων web και παρόμοιων τεχνολογιών. Το
          πρόγραμμα περιήγησής σας και άλλες επιλογές μπορεί να επηρεάσουν την
          εμπειρία σας με τα προϊόντα μας.</p>
        <h3><b>Χρήση Προσωπικών δεδομένων</b></h3>
        <p>Η Souldesign χρησιμοποιεί τα δεδομένα που συλλέγει αποκλειστικά για
          τους παρακάτω σκοπούς:</p>
        <ol>
          <li>Για τη διεξαγωγή των επιχειρηματικών της δραστηριοτήτων και την
            παροχή (συμπεριλαμβανομένης της βελτίωσης και της εξατομίκευσης) των
            προϊόντων και υπηρεσιών που προσφέρει.
          </li>
          <li>Για την αποστολή επικοινωνιών, συμπεριλαμβανομένου διαφημιστικού
            υλικού μέσω ηλεκτρονικού ταχυδρομείου ή τηλεφωνικής συνδιάλεξης
            (εμπορική προώθηση, προσφορές κα).
          </li>
        </ol>
        <p>Τα δεδομένα που συλλέγουμε δεν τα μοιραζόμαστε με νομικά ή φυσικά
          πρόσωπα παρά μόνο μετά τη συγκατάθεση του πελάτη, για την ολοκλήρωση
          οιασδήποτε συναλλαγής ή για την παροχή υπηρεσίας, όπως για παράδειγμα
          τα δεδομένα πληρωμής, θα τα μοιραστούμε με τις τράπεζες και άλλους
          φορείς που επεξεργάζονται συναλλαγές πληρωμών ή παρέχουν άλλες
          χρηματοπιστωτικές υπηρεσίες και για την πρόληψη της απάτης και τη
          μείωση του πιστωτικού κινδύνου.</p>
        <p>Μπορεί τέλος να αποκτήσουμε πρόσβαση, να μεταφέρουμε, να αποκαλύψουμε
          και να διατηρήσουμε προσωπικά δεδομένα, όταν πιστεύουμε καλόπιστα, ότι
          κάτι τέτοιο είναι απαραίτητο για τους παρακάτω λόγους:</p>
        <ol>
          <li>Τη συμμόρφωση με την ισχύουσα νομοθεσία ή για να ανταποκριθούμε σε
            έγκυρη νομική διαδικασία, η οποία μπορεί να προέρχεται επίσης από
            τις αστυνομικές αρχές ή άλλες κρατικές υπηρεσίες
          </li>
          <li>Τη λειτουργία και συντήρηση της ασφάλειας των προϊόντων και
            υπηρεσιών που σας προσφέρουμε, συμπεριλαμβανομένης της πρόληψης ή
            της διακοπής μιας επίθεσης στα συστήματα υπολογιστών ή τα δίκτυά μας
            ή την προστασία των δικαιωμάτων ή της ιδιοκτησίας της Souldesign.
          </li>
        </ol>
        <h3><b>Χρονικό διάστημα επεξεργασίας των Προσωπικών Δεδομένων</b></h3>
        <p>Η αποθήκευση και επεξεργασία των δεδομένων των πελατών και χρηστών
          των υπηρεσιών μας γίνεται αποκλειστικά και μόνο όπως επιτρέπεται από
          τον νόμο ή σύμφωνα με τη ρητή συγκατάθεση των χρηστών μόνο για όσο
          απαιτείται, ώστε να ικανοποιούνται οι σκοποί της επεξεργασίας (όπως
          ορίστηκαν πιο πάνω) ή μέχρι ο χρήστης να διαφωνήσει με την χρήση των
          Προσωπικών Δεδομένων από την Souldesign ή μέχρι να αποσύρει τη
          συγκατάθεση του. Ωστόσο, όποτε απαιτείται από υποχρεωτικό νόμο η Souldesign θα διατηρεί τα Προσωπικά Δεδομένα για μεγαλύτερο χρονικό
          διάστημα ή όποτε τα Προσωπικά Δεδομένα απαιτούνται, έτσι ώστε η SOUL
          Creative Agency να διεκδικήσει ή να υπερασπίσει τον εαυτό της έναντι
          νομικών αξιώσεων.</p>
        <h3><b>Τόπος επεξεργασίας των δεδομένων</b></h3>
        <p>Η έδρα και το υποκατάστημα της Souldesign βρίσκονται στην Ελλάδα,
          συνεπώς όλες οι επεξεργασίες που πραγματοποιούνται στις εγκαταστάσεις
          μας βρίσκονται εντός της Ελληνικής επικράτειας. Συγκεκριμένα: Στην
          Αθήνα, οδός Μυκάλης 11, 10435 Κεραμεικός.</p>
        <h3><b>Τρόπος πρόσβασης στα προσωπικά δεδομένα σας και ελέγχου αυτών</b>
        </h3>
        <p>Οι χρήστες των προϊόντων και των υπηρεσιών της Souldesign μπορούν
          ανά πάσα στιγμή να ζητήσουν πληροφορίες σχετικά με τα Προσωπικά
          Δεδομένα τους, αλλά και την διόρθωση ή την διαγραφή τους. Ασφαλώς αν
          γίνει διαγραφή των δεδομένων ενός χρήστη τότε η Souldesign δεν είναι
          σε θέση να συνεχίσει να παρέχει τις υπηρεσίες της στον χρήστη αυτόν,
          όταν αυτές απαιτούν την χρήση των δεδομένων αυτών.
          Στην περίπτωση που η Souldesign χρησιμοποιεί τα προσωπικά δεδομένα
          σύμφωνα με τη συγκατάθεση του χρήστη ή για να συνάψει σύμβαση μαζί
          του, ο χρήστης μπορεί να ζητήσει ένα αντίγραφο των προσωπικών του
          δεδομένων.
        </p>
        <p>Οι χρήστες τέλος μπορούν να ζητήσουν από την Souldesign να
          περιορίσει την οποιαδήποτε επεξεργασία των Προσωπικών Δεδομένων τους
          στις παρακάτω περιπτώσεις:</p>
        <ol>
          <li>Τα Προσωπικά Δεδομένα που έχει η Souldesign είναι λανθασμένα
          </li>
          <li>Δεν υπάρχει νομική βάση για να επεξεργαστεί η Souldesign τα
            Προσωπικά Δεδομένα και ο χρήστης απαιτεί να περιοριστεί η
            επεξεργασία τους
          </li>
        </ol>
        <p>Για οποιοδήποτε αίτημα ή διευκρίνηση χρειάζεται, οι χρήστες μπορούν
          να επικοινωνήσουν μέσω της παρακάτω διεύθυνσης email, καθορίζοντας τις
          πληροφορίες ή τις δραστηριότητες επεξεργασίας που σχετίζονται με το
          αίτημά τους, τη μορφή με την οποία θέλουν αυτές τις πληροφορίες και το
          εάν τα Προσωπικά Δεδομένα θα σταλούν σε αυτούς ή σε άλλον παραλήπτη.
          Το αίτημα θα εξεταστεί προσεκτικά και θα συζητήσουμε με τον χρήστη την
          καλύτερη εκπλήρωσή του.</p>
        <h3><b>Πληροφορίες επικοινωνίας</b></h3>
        <p>Ο Υπεύθυνος της Επεξεργασίας είναι η Souldesign Ε.Ε, οδός Μυκάλης
          11, Κεραμεικός τηλ. +30 210 3416904 e-mail: hello@souldesign.gr. Η
          εταιρία δεν υποχρεούται σύμφωνα με τον ΓΚΠΔ να ορίσει Υπεύθυνο
          Προστασίας Δεδομένων.</p>
        <h3><b>Αλλαγές στην παρούσα πολιτική</b></h3>
        <p>Η Souldesign μπορεί να τροποποιήσει την παρούσα Δήλωση Προστασίας
          Προσωπικών Δεδομένων προκειμένου κάθε φορά να ανταποκρίνεται στις
          ισχύουσες πρακτικές προστασίας των προσωπικών δεδομένων. Όταν κάνουμε
          αλλαγές στην παρούσα δήλωση, θα ενημερώνουμε την "ημερομηνία
          ενημέρωσης" στο κάτω μέρος αυτής της σελίδας. Σας ενθαρρύνουμε να
          μελετάτε κατά περιόδους την παρούσα Δήλωση Προστασίας Προσωπικών
          Δεδομένων προκειμένου να πληροφορείστε αναφορικά με τον τρόπο που η
          Souldesign προστατεύει τα δεδομένα σας.</p>
        <h3><b>Ερωτήσεις περί της πολιτικής και της εφαρμογής της</b></h3>
        <p>Η Souldesign έχει δεσμευτεί να προστατεύει τα προσωπικά σας
          δεδομένα στο διαδίκτυο. Εάν έχετε ερωτήσεις ή παρατηρήσεις σχετικά με
          την διαχείριση των προσωπικών σας δεδομένων από εμάς, παρακαλούμε να
          επικοινωνήσετε μαζί μας στη διεύθυνση και τα στοιχεία που αναφέρονται
          ανωτέρω, στις πληροφορίες επικοινωνίας. Μπορείτε επίσης να
          χρησιμοποιήσετε αυτή τη διεύθυνση προκειμένου να μας ανακοινώσετε
          όποιες ανησυχίες τυχόν έχετε σχετικά με τη συμμόρφωση προς την παρούσα
          Δήλωση απορρήτου και προστασίας Προσωπικών Δεδομένων.</p>
        <p>Σε αυτόν τον ιστότοπο, οι όροι , "εμείς", "μας", και "εμάς"
          αναφέρονται στην Souldesign.</p></div>
      {popupIsOpen && <CookiePopup dispatch={dispatch} value={state} />}
    </div>
    )}
  </CookieConsentContext.Consumer>
)

export default PrivacyPage
